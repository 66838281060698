<template>
  <!-- 修改审核结果 -->
  <div class="change-auth-examin-component">
    <ColorTextBtn class="color-btn-wrapper" :disabled="isChange" @click="showHandler"> 修改审核结果 </ColorTextBtn>
    <div class="valid-pass-form-wrapper">
      <ValidPassForm
        v-if="isChange"
        :data="cloneData"
        @doCancel="isChange = !isChange"
        v-on="$listeners"
      />
    </div>
  </div>
</template>

<script>
import ValidPassForm from './validPassForm'
import cloneDeep from 'lodash/cloneDeep'
export default {
  components: {
    ValidPassForm
  },

  props: {
    data: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      isChange: false,
      cloneData: {}
    }
  },

  methods: {
    showHandler() {
      this.cloneData = cloneDeep(this.data)
      this.isChange = !this.isChange
    }
  }
}
</script>

<style lang="scss" scoped>
.change-auth-examin-component {
  display: flex;
  align-items: flex-start;
  padding: 15px;
  .color-btn-wrapper {
    position: relative;
    top: -10px;
    margin-right: 20px;
  }
  .valid-pass-form-wrapper {
    flex: 1;
  }
}
</style>
